import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import Asset from "../../components/Asset";

import styles from "../../styles/ProfilePage.module.css";
import appStyles from "../../App.module.css";
import btnStyles from "../../styles/Button.module.css";

import PopularProfiles from "../../components/profiles/PopularProfiles";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { useParams } from "react-router";
import { axiosReq } from "../../api/axiosDefaults";
import {
  useProfileData,
  useSetProfileData,
} from "../../contexts/ProfileDataContext";
import { Button, Image } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Post from "../posts/Post";
import { fetchMoreData } from "../../utils/utils";
import NoResults from "../../assets/no-results.webp";
import { ProfileEditDropdown } from "../../components/MoreDropdown";
import Fab from "@mui/material/Fab";
import PopularPosts from "../../components/posts/PopularPosts";
import FoodSnapMessage from "../../components/FoodSnapMessage";

function ProfilePage() {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [profilePosts, setProfilePosts] = useState({ results: [] });

  const currentUser = useCurrentUser();
  const { id } = useParams();

  const { setProfileData, handleFollow, handleUnfollow } = useSetProfileData();
  const { pageProfile } = useProfileData();

  const [profile] = pageProfile.results;
  const is_owner = currentUser?.username === profile?.owner;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [{ data: pageProfile }, { data: profilePosts }] =
          await Promise.all([
            axiosReq.get(`/profiles/${id}/`),
            axiosReq.get(`/posts/?owner__profile=${id}`),
          ]);
        setProfileData((prevState) => ({
          ...prevState,
          pageProfile: { results: [pageProfile] },
        }));
        setProfilePosts(profilePosts);
        setHasLoaded(true);
      } catch (err) {
        // console.log(err);
      }
    };
    fetchData();
  }, [id, setProfileData]);

  const mainProfile = (
    <>
      {profile?.is_owner && <ProfileEditDropdown id={profile?.id} />}
      <Row noGutters className="px-3 text-center">
        <Col lg={3} className="text-lg-left">
          <Image
            className={styles.ProfileImage}
            roundedCircle
            src={profile?.image}
          />
        </Col>
        <Col lg={6}>
          <h3 className={`${styles.ProfileName} "m-2"`}>{profile?.owner}</h3>
          <Row className="justify-content-between no-gutters">
            <Col xs={3} className="my-2">
              <Fab disabled size="small" color="black" aria-label="posts">
                <div className={styles.CountNumber}>{profile?.posts_count}</div>
              </Fab>
              {profilePosts.results.length === 1 ? (
                <div className={styles.CountText}>foodSNAP</div>
              ) : (
                <div className={styles.CountText}>foodSNAPS</div>
              )}
            </Col>
            <Col xs={3} className="my-2">
              <Fab disabled size="small" color="black" aria-label="followers">
                <div className={styles.CountNumber}>
                  {profile?.followers_count}
                </div>
              </Fab>
              {profile?.followers_count === 1 ? (
                <div className={styles.CountText}>follower</div>
              ) : (
                <div className={styles.CountText}>followers</div>
              )}
            </Col>
            <Col xs={3} className="my-2">
              <Fab disabled size="small" color="black" aria-label="following">
                <div className={styles.CountNumber}>
                  {profile?.following_count}
                </div>
              </Fab>
              <div className={styles.CountText}>following</div>
            </Col>
          </Row>
        </Col>
        <Col lg={3} className="text-lg-right">
          {currentUser &&
            !is_owner &&
            (profile?.following_id ? (
              <Button
                className={`${btnStyles.Button} ${btnStyles.BlackOutline}`}
                onClick={() => handleUnfollow(profile)}
              >
                unfollow
              </Button>
            ) : (
              <Button
                className={`${btnStyles.Button} ${btnStyles.Black}`}
                onClick={() => handleFollow(profile)}
              >
                follow
              </Button>
            ))}
        </Col>
        {profile?.content && (
          <Col className={`${styles.ProfileContent} "p-3"`}>
            {profile.content}
          </Col>
        )}
      </Row>
    </>
  );

  const mainProfilePosts = (
    <>
      <FoodSnapMessage message={`${profile?.owner} Created`} />
      {profilePosts.results.length ? (
        <InfiniteScroll
          children={profilePosts.results.map((post) => (
            <Post key={post.id} {...post} setPosts={setProfilePosts} />
          ))}
          dataLength={profilePosts.results.length}
          loader={<Asset spinner />}
          hasMore={!!profilePosts.next}
          next={() => fetchMoreData(profilePosts, setProfilePosts)}
        />
      ) : (
        <Asset
          src={NoResults}
          message={`No results found, ${profile?.owner} hasn't added any foodSNAPS yet.`}
        />
      )}
    </>
  );

  return (
    <Row>
      <Col className="py-2 p-0 p-lg-2" lg={8}>
        <PopularProfiles mobile />
        {hasLoaded ? (
          <>
            <Container className={appStyles.Content}>{mainProfile}</Container>

            {mainProfilePosts}
          </>
        ) : (
          <Asset spinner />
        )}
      </Col>
      <Col lg={4} className="d-none d-lg-block p-0 p-lg-2">
        <PopularProfiles />
        <PopularPosts />
      </Col>
    </Row>
  );
}

export default ProfilePage;
